<template>
    <OColumnGroup :groupId="column.groupId" :key="column.groupId" :parentGroupId="parentId" :headerName="column.groupId"  :children="column.children">
        <template #headertext>
            <span class="w-100" :class="{'text-start': column.groupNameAlignment == 'Start', 'text-center': column.groupNameAlignment == 'Center', 'text-end': column.groupNameAlignment == 'End'}">
                {{ column.groupId }}
            </span>
        </template>
        <template v-for="col in childColumns">
            <TableLayoutColumnGroup v-if="col.groupId" :columnData="col" :parentId="column.groupId" />
            <TableLayoutColumn v-else-if="col.field" :columnData="col" :fields="fields"/>

            <OColumn v-else colId="NoColumnsFound" :headerName="$t('No Columns Found')"></OColumn>
        </template>
    </OColumnGroup>
</template>

<script setup lang="ts">
    import { ref, Ref, onMounted, onBeforeMount } from "vue";
    import TableLayoutColumn from "status.vue.components.tableLayoutComponents.TableLayoutColumn.vue";
    import { $t } from 'o365-utils';

    const props = defineProps({
        column: {
            type: Object,
            required: true,
            default: []
        },
        parentId: {
            default: null
        },
        fields: {
            type: Array,
            required: true,
            default: []
        }
    })
    const childColumns:Ref<Array<any>> = ref([]);

    onBeforeMount(() => {
        childColumns.value = props.column.children;
    });
</script>